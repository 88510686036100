import Axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';

// Import Main styles for this application
import './assets/css/fonts.css'
import './assets/css/style.css'
import './assets/css/custom.css'
import { Images } from './utils/Images';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function App() {
  const [processing, setProcessing] = useState([]);
  const [ready, setReady] = useState([]);

  const APIHOST = "https://krisflyer-api.catchnshop.com/api/"

  useEffect(()=>{
    setInterval(()=>{loadQueue()}, 3000)
  },[])

  Axios.defaults.headers.common['Authorization'] = "Bearer lSggXheA50T9cDGHsrCUXyHqVPbT7Q2KskpJIAI1Zoxqf04b2Y";

  const loadQueue = () =>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
  Axios.post(`${APIHOST}booth/queue`, {
      booth: urlParams.get("booth")
  }).then(function(res){
      let {data} = res;
      setProcessing(data.processing)
      setReady(data.ready)
  }).catch(function(er){
      if(typeof(er.response) !== "undefined"){
          if(er.response.status == 404 || er.response.status == 500){
            console.log(er.response.data.error ?? er.response.data.message)
          }else{
            console.log("A network error occured.")
          }
      }else{
        console.log(er.message)
      }
  })
  }

  return (
    <div className="App">
      <div class="main">
        <div class="outer-border">
              <div class="inner-content">
                   <div class="inner-logo">
                    <img src={Images.asset2} alt="" />
                    <div class="left-after"></div>
                    <div class="right-before"></div>
                   </div>
                   <div class="main-content">
                    <div class="bg1"></div>
                    <div class="bg2"></div>
                     <div class="main-box1 text-center">
                        <button class="btn top-btn ready-btn">Ready</button>
                        <div class="box-inner-data ready-inner-btn">
                          {ready.map((tkt)=> <button class="btn">{tkt.ticket_no}</button>)}  
                        </div>
                     </div>
                     <div class="main-box1 text-center mt-box">
                        <button class="btn top-btn processing-btn">Processing</button>
                        <div class="box-inner-data processing-inner-btn">
                          {processing.map((tkt)=> <button class="btn">{tkt.ticket_no}</button>)}       
                        </div>
                     </div>
                   </div>
              </div>
        </div> 
    </div>
    </div>
  );
}

export default App;
